@media only screen and (max-width: 900px) {
    .patientBox {
      width: 60%;
      border: 2px solid;
    }
  
    .Payment_Page {
      /* border: 1px solid blue; */
      width: 30%;
    }
  }