/* *********************************  TOP BAR STYLE PART  ****************************************/

.MainDiv {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  padding: 10px 40px;
  align-items: center;
  gap: 1rem;
  background-color: white;
  margin-bottom: 2rem;
  border-radius: 10px;
  box-shadow: 0px 0px 7px -3px rgb(101, 101, 101);
}

.IconsDiv {
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: center;
}

.SearchDiv {
  width: 60%;
}

.Icons {
  font-size: 2rem;
  cursor: pointer;
  color: rgb(74, 70, 70);
}

.SearchDiv input {
  width: 100%;
  height: 2.5rem;
  border-radius: 5px;
  border: none;
  font-size: 1.2rem;
  padding-left: 10px;
  background-color: rgba(204, 12, 123, 1);
}

.Hideshow {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.Hideshowicon {
  font-size: 2rem;
  color: white;
  cursor: pointer;
}

/* **********************   SIDEBAR STYLE PART  ************************* */
.container {
  display: flex;
  gap: 2rem;
  background-color: #423e37;
  width: 100%;
  /* border: 2px solid green; */
  width: 100%;
}
main {
  width: 100%;
  padding: 20px;
}

.mainIcon {
  color: white;
  font-size: 2rem;
}

.sidebar {
  background: rgba(204, 12, 123, 1);
  color: #fff;
  height: 100vh;
  transition: all 0.5s;
  position: sticky;
  top: 0;
}
.top_section {
  display: flex;
  align-items: center;
  padding: 20px 15px;
  justify-content: center;
}

.logo {
  font-size: 30px;
}
.bars {
  display: flex;
  font-size: 25px;
  margin-left: 50px;
}
.link {
  display: flex;
  color: #fff;
  padding: 10px 15px;
  gap: 15px;
  text-decoration: none;
  list-style: none;
  align-items: center;
  transition: all 0.5s;
}
.link:hover {
  background: #423e37;
  color: #ffffff;
  transition: all 0.5s;
}
.active {
  background: #423e37;
  color: #ffffff;
}
.icon,
.link_text {
  font-size: 15px;
}

.LogOutPath .icon {
  font-size: 2rem;
}

.AfterSideBar {
  width: 90%;
  margin: 2rem;
}

/**************************************** FRONT PAGE STYLE  *****************************************/
.contentWrapper {
  display: flex;
  justify-content: space-between;
}

.maindiv {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 50%;
  gap: 2rem;
  margin-top: 2rem;
}

.rightSide {
  width: 50%;
  margin-left: 2rem;
}

.doughnut {
  margin-left: 6rem;
  width: 350px !important;
  height: 350px !important;
}


.commondiv {
  border: 2px solid;
  height: 8rem;
  border-radius: 1rem;
  text-align: center;
}

.patientDetails {
  margin-top: 5rem;
}
.patientDetails h1 {
  margin-bottom: 1rem;
  color: rgba(204, 12, 123, 1);
}

.patientBox {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;
  background-color: white;
  border-radius: 1rem;
  border: none;
  box-shadow: 0px 0px 36px -3px #00000026;
}

.commondiv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  box-shadow: 0px 0px 36px -3px #00000026;
  border: none;
  background-color: white;
  border: 2px solid rgba(204, 12, 123, 1)
}

.custom-table {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 6px rgba(0,0,0,0.1);
}

.custom-table .ant-table-tbody > tr > td {
  background-color: white !important;
  border: 1px solid #ebebeb83;

}


.custom-table .ant-table-thead {
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  border: 1px solid #ebebeb83;
}

.custom-table .ant-table-tbody > tr > td {
  color: #333;
}

.overviewIcon {
  font-size: 4rem;
  color: rgba(204, 12, 123, 1);
}

.pop-up-container {
  display: flex;
  flex-direction: column; 
  align-items: center;
  animation: popIn 1s ease-out forwards;
  max-height: 550px;
  max-width: 750px;
  border-radius: 12px;
}

.pop-up {
  animation: popIn 1s ease-out forwards;
  width: 700px;
  height: 400px;
  justify-content: center;
  text-align: center;
  background-color: rgb(255, 255, 255);
  color: black;
  border-radius: 15px;
}

.pop-up-button {
  background-color: rgba(204, 12, 123, 1);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.pop-up-title {
  animation: popIn 0.6s ease-out forwards;
  font-size: 1.5rem;
  background-color: rgba(204, 12, 123, 1);
  margin: 20px 0 20px 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.pop-up-close {
  animation: popIn 0.6s ease-out forwards;
  background-color: rgba(204, 12, 123, 1);
  color: rgba(255, 255, 255, 0.719);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 5px;
  cursor: pointer;
}

.pop-up-container-info{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: flex-end;
  align-items: center;
}

.pop-up-score{
  display: flex;
  align-items: center;
  font-size: larger;
  justify-content:center;
  width: 20%;
  height: 50px; 
  border-radius: 20px; 
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.pop-up-sumup {
  color: rgb(0, 0, 0);
  font-size: 0.8rem;
  text-align: justify;
  /* text-align: left; */
  width: 500px;
  margin-top: 3%;
  margin-bottom: 3%;

}

.pop-up-kpi{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2%;
}



@keyframes popIn {
  0% {
    opacity: 0;
    transform: scale(0.5) rotateX(-30deg);
    transform-origin: center;
    perspective: 1000px;
  }
  50% {
    opacity: 0.5;
    transform: scale(1.05) rotateX(10deg);
  }
  100% {
    opacity: 1;
    transform: scale(1) rotateX(0deg);
  }
}

/* ******************************************************** */

@media only screen and (max-width: 1200px) {
  .maindiv {
    grid-template-columns: repeat(3, 1fr);
  }
  .commondiv {
    gap: 2.5rem;
  }
}

@media only screen and (max-width: 850px) {
  .maindiv {
    grid-template-columns: repeat(2, 1fr);
  }
  .commondiv {
    gap: 4rem;
  }
  .container {
    width: 100%;
  }
}

@media only screen and (max-width: 550px) {
  .maindiv {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (max-width: 600px) {
  .container {
    gap: 10px;
  }
}

@media only screen and (max-width: 450px) {
  .hidesidebar {
    display: none;
  }
  .showsidebar {
    display: block;
  }
  .Icons {
    font-size: 1.3rem;
  }
  .IconsDiv {
    gap: 15px;
    border: 1px solid;
  }
  .MainDiv {
    border: 1px solid;
  }
}
