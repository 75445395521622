.mainLoginPage {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10rem;
  height: 100vh;
  background-color: #000000;
  animation: fadeIn 2s ease-in-out forwards;
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: black;
  animation: fadeInOut 4s ease-in-out forwards;

}

.form {
  padding: 2rem;
  align-items: center;
  text-align: center;
  width: 35%;
  border-radius: 10px;
  background-color: white;
}

.radiogroup .radiobutton {

  width: 50%;
 
  margin-left: 2%;
  margin-top: 5px;
  border-radius: 10px;
  border: none;
  background-color: rgba(204, 12, 123, 1);
  color: white;
  /* text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 10px;
  border: none;
  padding: 10px 20px;
  background-color: rgba(204, 12, 123, 1);
  color: white;
  margin-right: 5px;
  margin-top: 5px;
  gap: 5px;
  cursor: pointer; */
  margin-bottom: 5%;
}

.radiobutton2 {
  width: 50%;
 
  margin-left: 2%;
  margin-top: 5px;
  border-radius: 10px;
  border: none;
  background-color: rgba(201, 200, 200, 0.413);
  color: white;
}

.radiogroup .radiobutton:hover {
  background-color: rgba(204, 12, 123, 0.8); /* Slightly lighter color on hover */
}

/* Active and focus styles to maintain the design when the button is active or focused */
.radiogroup .radiobutton:active,
.radiogroup .radiobutton:focus {
  outline: none;
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.3); /* Inner shadow for a "pressed" effect */
  border-left:1px solid #000000 !important;

}

/* Adjust the checked radio button style to have a distinct look */
.radiogroup .radiobutton.ant-radio-button-wrapper-checked {
  background-color: rgba(204, 12, 123, 1); /* Keep background the same */
  color: white; /* Keep text color the same */
  box-shadow: none;
  border-left:1px solid #000000 !important;
}

.radiogroup .radiobutton.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  background-color: rgba(204, 12, 123, 1);
  border-left:1px solid #000000 !important;
}

.radiogroup .radiobutton.ant-radio-button-wrapper-checked:hover {
  background-color: rgba(204, 12, 123, 0.9);
}

.Profileimg {
  margin: auto;
  width: 26%;
  margin-top: 2rem;
}

.Profileimg img {
  width: 5rem;
  border-radius: 5rem;
  /* border: 1px solid red; */
}

.radiobutton {
  width: 33%;
  font-size: 1.2rem;
  font-weight: bold;
}

.form > h1 {
  margin: 2rem;
}

.form input {
  display: block;
  width: 100%;
  background-color: rgba(204, 12, 123, 0.5);
  height: 3rem;
  font-size: 1.2rem;
  margin: 10px 0px;
  border-radius: 5px;
  border: none;
  padding: 10px;
}

.form button {
  width: 100%;
  height: 2.5rem;
  border-radius: 2rem;
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: bolder;
  background-color: rgba(204, 12, 123, 1);
  border: none;
  margin-top: 1.5rem;
}

form h3 {
  text-align: left;
}

@media only screen and (max-width: 1024px) {
  .mainLoginPage {
    gap: 5rem;
  }
  .form {
    width: 40%;
  }
}

@media only screen and (max-width: 770px) {
  .mainLoginPage {
    gap: 0rem;
  }

  .form {
    width: 60%;
  }
}

@media only screen and (max-width: 550px) {
  .form {
    width: 80%;
  }
}

@media only screen and (max-width: 400px) {
  .form {
    width: 95%;
  }
}
